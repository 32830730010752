<template>
  <section class="section">
    <div class="container">
      <b-loading
        v-model="loading"
        :is-full-page="false"
        class="is-primary"
      >
      </b-loading>
      <div class="content mt-6 has-text-centered">
        <b-icon
          pack="far"
          icon="check-circle"
          class="fa-7x"
          type="is-success"
        >
        </b-icon>
        <h1 class="is-size-2">{{ $t('confirmation.title') }}</h1>
        <p class="is-size-5">{{ $t('confirmation.yourFormNumber') }} <b>{{ $route.params.formId }}</b></p>
        <p class="is-size-5 mb-0">{{ $t('confirmation.summary.emailConfirmation') }}</p>
        <p class="is-size-5">{{ $t('confirmation.summary.treatmentDelay') }}</p>
        <b-button
          tag="router-link"
          :to="{ name: 'otherservices' }"
          type="is-primary"
          size="is-medium"
        >
          {{ $t('confirmation.otherServices') }}
        </b-button>
      </div>
    </div>
  </section>
</template>

<script>
import api from '@/api';

export default {
  name: 'Confirmation',
  data () {
    return {
      loading: true
    };
  },
  mounted () {
    api.confirmOrder(this.$route.params.formId, this.$route.query.token, this.$route.query.PayerID)
      .then(() => {
        this.loading = false;
      })
      .catch(() => {
        this.$router.push('/');
      });
  }
};
</script>
